// src/components/CaseStudyStyles.js

import styled from "styled-components";

// TextSection
export const TextSection = styled.section`
  width: 100%;
  background-color: #282828;
  color:rgb(255, 255, 255);
  padding: 2rem 0;
  text-align: left;

  .content {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;

    h1 {
      font-size: 3rem;
      font-weight: bold;
      margin-bottom: -0.1rem;
      color:rgb(255, 255, 255);
    }

    h2 {
      font-size: 1.2rem;
      font-weight: bold;
      color: #23d997;
      margin-bottom: -0.12em;
      text-transform: uppercase;
    }

    p {
      font-size: 1rem;
      line-height: 1.6;
      margin-top: -0.10rem;
      color:rgb(255, 255, 255);
    }
  }
`;

// CaseStudyContainer
// Updated CaseStudyContainer
export const CaseStudyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  background-color: #282828; /* Set to white or match the surrounding background */
`;

export const YellowBanner = styled.section`
  display: flex; /* Align items horizontally */
  flex-direction: row; /* Place text and image side by side */
  align-items: center; /* Vertically align text and image */
  justify-content: space-between; /* Add space between text and image */
  width: 100%;
  height: auto; /* Dynamic height based on content */
  min-height: 60vh; /* Ensure sufficient height for visual balance */
  padding: 3rem 5%; /* Add padding for spacing */
  background-color: #282828;
  color:rgb(255, 255, 255);

  .banner-content {
    flex: 1.3; /* Text takes a smaller portion of the space */
    text-align: left;
    margin-right: 20px; /* Adjust the value as needed for the gap */


    h1 {
      font-size: 4rem;
      font-weight: bold;
      margin: 0 0 1rem 0;
      color: rgb(255, 255, 255);
    }

    p {
      font-size: 1.5rem;
      line-height: 1.6;
      margin: 0;
      color:rgb(255, 255, 255);
    }
  }

  .banner-image {
    flex: 1.2; /* Image takes a larger portion of the space */
    display: flex;
    justify-content: flex-end; /* Align image to the right */
    align-items: center;
    margin-left: -0.5rem; /* Add spacing between text and image */

    img {
      width: 700px; /* Increased width for a larger image */
      height: 400px; /* Adjust height to maintain aspect ratio */
      border-radius: 8px; /* Optional: Add rounded corners */
      object-fit: cover; /* Ensure the image doesn't stretch */
  
    }
  }

  @media (max-width: 768px) {
    flex-direction: column; /* Stack content vertically on smaller screens */
    align-items: center;
    text-align: center;

    .banner-content {
      margin-bottom: 2rem; /* Add space between content and image */
    }

    .banner-image {
      margin-left: 0; /* Remove left margin on smaller screens */
      img {
        width: 90%; /* Adjust image size for smaller screens */
        height: auto; /* Maintain aspect ratio */
      }
    }
  }
`;





// WhiteSection
export const WhiteSection = styled.section`
  width: 100%;
  background-color: #282828;
  color:rgb(255, 255, 255);
  padding: 3rem 0;

  .content {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;

    h1 {
      font-size: 3rem;
      font-weight: bold;
      margin: 0;
    }

    h2 {
      font-size: 1.5rem;
      color: #23d997;
      margin: 0;
    }

    p {
      margin-top: 0.5rem;
      font-size: 1rem;
      line-height: 1.8;
      text-align: left;
      color:rgb(0, 0, 0);

    }
  }
`;

// ProblemSection
export const ProblemSection = styled.section`
  width: 100%;
  background-color: #282828;
  color:rgb(255, 255, 255);
  padding: 3rem 0;

  .content-container {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .text-content {
      flex: 3;
      margin-right: 2rem;

      .highlight {
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;

        hr {
          width: 40px;
          height: 4px;
          background-color:rgb(255, 255, 255);
          border: none;
          margin-right: 10px;
        }

        span {
          font-size: 1.2rem;
          font-weight: bold;
          color: #23d997;
        }
      }

      h1 {
        font-size: 2.5rem;
        font-weight: bold;
        margin-bottom: 1rem;
      }

      p {
        font-size: 1rem;
        color:rgb(255, 255, 255);

        line-height: 1.8;
        margin-bottom: 1rem;
      }
    }

    .image-container {
      flex: 2;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      img {
        width: 100%;
        max-width: 500px;
        height: auto;
        max-height: 400px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(255, 255, 255, 0.9);
      }
    }
  }
`;

// YellowBox
export const YellowBox = styled.div`
  background-color: #23d997;
  color: #000000;
  padding: 1.5rem;
  border-radius: 15px;
  margin: 2rem auto;
  width: 90%;
  max-width: 1200px;

  h2 {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.6;
    margin: 0;
    word-wrap: break-word;
    overflow-wrap: break-word;
    color:rgb(0, 0, 0);
    hyphens: auto;
  }

  @media (max-width: 768px) {
    padding: 1rem;
    h2 {
      font-size: 1.5rem;
    }
    p {
      color:rgb(0, 0, 0);

      font-size: 0.9rem;
    }
    width: 95%;
  }
`;

// SolutionSection
export const SolutionSection = styled.section`
  width: 100%;
  background-color: #282828;
  color:rgb(255, 255, 255);
  padding: 3rem 0;

  .content-container {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;

    .image-container {
      flex: 2;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      img {
        width: 100%;
        max-width: 500px;
        height: auto;
        max-height: 400px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      }
    }

    .text-content {
      flex: 3;
      margin-left: 2rem;

      .highlight {
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;

        hr {
          width: 40px;
          height: 4px;
          background-color: rgb(255, 255, 255);;
          border: none;
          margin-right: 10px;
        }

        span {
          font-size: 1.2rem;
          font-weight: bold;
          color: #23d997;
        }
      }

      h1 {
        font-size: 2.5rem;
        font-weight: bold;
        margin-bottom: 1rem;
      }

      p {
        color:rgb(255, 255, 255);

        font-size: 1rem;
        line-height: 1.8;
        margin-bottom: 1rem;
      }
    }
  }
`;

// ImpactSection
// Updated ImpactSection: Image on the left, text on the right
export const ImpactSection = styled.section`
  width: 100%;
  background-color: #282828; /* Matches page background */
  color:rgb(255, 255, 255);
  padding: 3rem 0;

  .content-container {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row; /* Image on the left, text on the right */
    align-items: center;

    .image-container {
      flex: 2;
      display: flex;
      justify-content: flex-start; /* Align image to the left */
      align-items: center;

      img {
        width: 100%;
        max-width: 500px; /* Ensure the image doesn't grow too large */
        height: auto;
        max-height: 400px; /* Maintain aspect ratio and prevent excessive height */
        border-radius: 8px; /* Optional: Rounded corners */
        box-shadow: 0 4px 8px rgba(255, 255, 255); /* Add subtle shadow */
      }
    }

    .text-content {
      flex: 3;
      margin-left: 2rem;

      .highlight {
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;

        hr {
          width: 40px;
          height: 4px;
          background-color:rgb(255, 255, 255);
          border: none;
          margin-right: 10px;
        }

        span {
          font-size: 1.2rem;
          font-weight: bold;
          color: #23d997; /* Yellow highlight */
        }
      }

      h1 {
        font-size: 2.5rem;
        font-weight: bold;
        margin-bottom: 1rem;
      }

      p {
        color:rgb(255, 255, 255);

        font-size: 1rem;
        line-height: 1.8;
        margin-bottom: 1rem;
      }
    }
  }

  @media (max-width: 768px) {
    .content-container {
      flex-direction: column; /* Stack image and text on smaller screens */

      .image-container {
        margin-bottom: 2rem; /* Add spacing below the image */
      }

      .text-content {
        margin-left: 0; /* Remove left margin on smaller screens */
      }
    }
  }
`;

export const ProcessSection = styled.section`
  width: 100%;
  background-color: #282828;
  color:rgb(255, 255, 255);
  padding: 3rem 0;

  .content-container {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row; /* Text on the left, image on the right */
    align-items: flex-start;

    .text-content {
      flex: 3;
      margin-right: 2rem;

      .highlight {
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;

        hr {
          width: 40px;
          height: 4px;
          background-color:rgb(255, 255, 255);
          border: none;
          margin-right: 10px;
        }

        span {
          font-size: 1.2rem;
          font-weight: bold;
          color: #23d997;
        }
      }

      h1 {
        font-size: 2.5rem;
        font-weight: bold;
        margin-bottom: 1rem;
      }

      p {
        color:rgb(255, 255, 255);

        font-size: 1rem;
        line-height: 1.8;
        margin-bottom: 1rem;
      }
    }

    .image-container {
      flex: 2;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      img {
        width: 100%;
        max-width: 500px;
        height: auto;
        max-height: 400px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(255, 255, 255  );
      }
    }
  }
`;
