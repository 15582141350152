import React from "react";
import "./ButtonStyles.css"; // Import the button styles
import ContactForm from "../components/ContactForm";
import ContactSocial from "../components/ContactSocial";
import ScrollTop from "../components/ScrollTop";
const ContactUs = () => {
  return (
    <div style={{ textAlign: "center", padding: "2rem" }}>
      <a className="non-styled-link" href="/contact">
        <button>Contact Us</button>
      </a>
    </div>
  );
};

export default ContactUs;
