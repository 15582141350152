import React from "react";
import styled from "styled-components";
import { Layout } from "../styles";
import Toggle from "./Toggle";
import { NavLink } from "react-router-dom";
import {Link} from "react-scroll";
// Animation
import { AnimateSharedLayout } from "framer-motion";
import { useScroll } from "../hooks/useScroll";

const FAQ = styled(Layout)`
  display: block;
  padding-top: 4rem;
  span {
    display: block;
  }
  h2 {
    padding-bottom: 2rem;
    font-weight: lighter;
    font-size: 2.5rem;
  }
  h1 {
    padding-bottom: 3rem;
    font-weight: lighter;
    font-size: 2.5rem;
  }
  h4 {
    font-size: 1.5em;
    letter-spacing: 1.3px;
    cursor: pointer;
  }
  .faq-line {
    background-color: #ccc;
    height: 0.2rem;
    margin: 2rem 0;
    width: 100%;
  }
  .question {
    padding: 0.5rem 0rem;
    svg {
      cursor: pointer;
    }
  }
  .answer {
    padding: 2rem 0;
    p {
      padding: 1rem 0;
      font-size: 1.2rem;
      letter-spacing: 1.5px;
    }
    ul {
      list-style-type: circle;
      li {
        margin-left: 2rem;
        color: #23d997;
        font-size: 1.1rem;
      }
    }
  }
  .header-inline {
    display: flex;
    justify-content: space-between;
  }
  line {
    stroke-width: 10px;
    stroke-linecap: round;
    fill: transparent;
  }
  /* Mobile devices iPhone, Pixel */
  @media only screen and (min-width: 320px) and (max-width: 539px) {
    display: flex;
    flex-direction: column;
    h2 {
      font-size: 2.5rem;
    }
    .question, .answer {
      width: 100%;
      font-size: 0.7rem;
    }
  }
`;

const FaqSection = () => {
  const [element] = useScroll();

  return (
    <FAQ id="faq" initial="hidden" ref={element} className="faq">
      <h1>
        Any Questions <span>FAQ</span>
      </h1>
      <AnimateSharedLayout>
        <Toggle title="How do I Start?">
          <div className="answer">
            <p>Let's have some meaningful conversations</p>
            <p>
              Easiest way to communicate with us is to email us with all the
              information you want to include describing your idea,
              requirements, and project budget.
              <Link
                className="styled-link"
                to="contact-us"
                style={{ width: "fit-content", color: "#23d997" }}
              >
                Contact Us
              </Link>
            </p>
          </div>
        </Toggle>
        <Toggle title="Why work with us?">
          <div className="answer">
            <p>
              <b>
                <em>CUSTOMER-FIRST APPROACH</em>
              </b>
            </p>
            <p>
              We prioritize understanding your unique needs and goals to deliver solutions that truly benefit your business. 
              Every project we undertake is tailored with your success in mind.
            </p>
            <p>
              <b>
                <em>TEAM OF EXPERIENCED ENGINEERS</em>
              </b>
            </p>
            <p>
              Our team comprises skilled professionals with expertise across various domains, including web, mobile, AI, and SQA. 
              We bring technical know-how and industry insight to every project.
            </p>
            <p>
              <b>
                <em>COLLABORATION AND COMMUNICATION</em>
              </b>
            </p>
            <p>
              We believe in open, transparent communication throughout the project lifecycle. 
              By collaborating closely with you, we ensure alignment at every step, making the process smooth and stress-free.
            </p>
          </div>
        </Toggle>
        {/* <Toggle title="Different Payment Methods">
          <div className="answer">
            <p>We offer a bunch of payment methods.</p>
            <p>
              We accept primarily{" "}
              <b>
                <em>VISA/Maestro</em>
              </b>{" "}
              debit card and credit card payment, which will include payback
              points that you will earn with every subscriptions. Take a look at
              our
              <NavLink
                className="styled-link"
                to="/subspack"
                style={{ width: "fit-content", color: "#23d997" }}
              >
                Subscriptions & Packages
              </NavLink>
            </p>
          </div>
        </Toggle> */}
        <Toggle title="What services do we offer?">
          <div className="answer">
            <p>
              We offer four core services:
              <ul>
                <li>Web Development</li>
                <li>Mobile App Development</li>
                <li>AI Development</li>
                <li>Software Quality Assurance (SQA)</li>
              </ul>
            </p>
            <p>
              Our team specializes in creating seamless, high-performance web and mobile solutions, integrating cutting-edge AI technologies, and ensuring the highest standards of quality through robust testing processes. 
              We are committed to delivering innovative solutions that drive your business forward.
            </p>
          </div>
        </Toggle>
      </AnimateSharedLayout>
    </FAQ>
  );
};

export default FaqSection;
