import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ssl_img from "../img/ssl_img.png";
import street_img from "../img/street_img.png";
import aira_img from "../img/aira_img.png";
import ideas_img from "../img/ideas_img.png";
import uwt from "../img/uwt.png";
import pdt from "../img/pdt.PNG";
import mdt from "../img/mdt.PNG";
import abt from "../img/abt.PNG";
// Styled Components for the Slider Section
const WorkSection = styled.div`
  padding: 2rem 0;
  text-align: center;

  h2 {
    font-size: 3rem;
    margin-bottom: 2rem;
    color: #23d997;
  }
`;

const SliderContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  position: relative;

  .slick-list {
    overflow: hidden;
  }

  .slick-slide {
    padding: 0 15px;
  }

  .slick-prev,
  .slick-next {
    position: absolute;
    top: 110%;
    transform: translateY(-50%);
    font-size: 30px;
    color: #23d997;
    z-index: 10;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .slick-prev {
    left: 45%;
  }

  .slick-next {
    right: 45%;
  }

  .slick-prev:before,
  .slick-next:before {
    display: none;
  }
`;

const Card = styled.div`
  background: #282828;
  border-radius: 1rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  text-align: center;
  padding: 1rem;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  max-width: 400px;
  margin: 1rem auto;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 25px 40px rgba(0, 0, 0, 0.3);
    background: #23d997; /* Change background color to green */
  }

  img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 0.8rem;
    margin-bottom: 0.8rem;
  }

  h3 {
    margin: 0.5rem 0;
    font-size: 1rem;
    color: #ffffff; /* Default white text color */
    transition: color 0.3s ease-in-out;

    &:hover {
      color: #000; /* Change text color to black */
    }
  }

  p {
    font-size: 0.8rem;
    color: #ffffff; /* Default white text color */
    word-wrap: break-word;
    white-space: normal;
    max-height: 3rem;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0.5rem 0;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: #000; /* Change text color to black */
    }
  }

  &:hover h3,
  &:hover p {
    color: #000; /* Ensure text changes to black on hover */
  }
`;

const CardLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: block; /* Wraps the card to make it fully clickable */

  &:hover {
    text-decoration: none;
  }
`;

const OurSuccess = () => {
  // Slider settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const cardData = [
    {
      id: "ideas",
      img: ideas_img,
      title: "IDEAS Foundation",
      description: "Empowering street children with access to quality education.",
    },
    {
      id: "aira",
      img: aira_img,
      title: "AIRA Photo Editor App",
      description: "A user-friendly app to enhance, edit, and share photos.",
    },
    {
      id: "ssl",
      img: ssl_img,
      title: "Students Social Life App",
      description: "Enhancing students' social connections and events.",
    },
    {
      id: "streetfeast",
      img: street_img,
      title: "StreetFeast App",
      description: "Connecting people with food-sharing opportunities.",
    },
    {
      id: "urduwebify",
      img: uwt,
      title: "Urdu Webify ",
      description: "Making Web Pages on Urdu Voice Command",
    },
    {
      id: "PredictVid",
      img: pdt,
      title: "PredictVid ",
      description: "Predict Video Frames Based On Input Frames ",
    },
    {
      id: "MedicalChatbot",
      img: mdt,
      title: "Medical Chatbot ",
      description: "Chatbot Based On Davidson's Principles Book ",
    },
    {
      id: "AiAutomatedBilling",
      img: abt,
      title: "Ai Automated Billing ",
      description: "Automating Billing By Image Detection And Generating Bill",
    },
  ];

  return (
    <WorkSection id="our-success">
      <h2>Our Work Speaks for Us</h2>

      <SliderContainer>
        <Slider {...settings}>
          {cardData.map((card) => (
            <CardLink to={`/case-study/${card.id}`} key={card.id}>
              <Card>
                <img src={card.img} alt={card.title} />
                <h3>{card.title}</h3>
                <p>{card.description}</p>
              </Card>
            </CardLink>
          ))}
        </Slider>
      </SliderContainer>
    </WorkSection>
  );
};

// Custom Next Arrow
const CustomNextArrow = ({ onClick }) => {
  return (
    <button
      className="slick-next"
      onClick={onClick}
      aria-label="Next Slide"
    >
      ❯
    </button>
  );
};

// Custom Previous Arrow
const CustomPrevArrow = ({ onClick }) => {
  return (
    <button
      className="slick-prev"
      onClick={onClick}
      aria-label="Previous Slide"
    >
      ❮
    </button>
  );
};

export default OurSuccess;
