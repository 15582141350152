import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { RiReactjsFill } from "react-icons/ri";
import { FaLaptop, FaMobileAlt, FaRobot, FaBug } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import serviceData from "../constants/serviceData";
import { TbBrandNextjs } from "react-icons/tb";
import { RiAngularjsLine } from "react-icons/ri";
const ServicesSection = () => {
  const navigate = useNavigate();
  const handleServiceClick = (service) => {
    // Exclude icon

    let formattedServiceTitle = service.title.replace(/ /g, "_");
    navigate(`/services/${formattedServiceTitle}`, {
      state: { id: service.id },
    });
  };

  const [isInView, setIsInView] = useState(false);
  const sectionRef = useRef(null); // Reference to track section visibility

  // Animation for cards appearing from opposite sides
  const cardVariants = {
    hidden: (direction) => ({
      x: direction === "left" ? "-100vw" : "100vw", // Start off-screen
      opacity: 0,
    }),
    show: {
      x: 0, // Move to original position
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 50,
        damping: 20,
      },
    },
  };

  const iconVariants = {
    animate: {
      y: [0, -10, 0], // Move up and down
      transition: {
        duration: 2,
        repeat: Infinity, // Repeat indefinitely
        ease: "easeInOut",
      },
    },
  };

  // Use IntersectionObserver to track when the section is in view
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
        }
      },
      { threshold: 0.1 } // Adjust threshold for earlier/later trigger
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    
    <Services
      id="our-services"
      ref={sectionRef}
      initial="hidden"
      animate={isInView ? "show" : "hidden"} // Trigger animation when in view
    >
      <h2>Our Services</h2>
      <Cards>
        {serviceData.map((service) => (
          <Card
            onClick={() => handleServiceClick(service)}
            key={service.id}
            custom={service.direction}
            variants={cardVariants}
            initial="hidden"
            animate={isInView ? "show" : "hidden"} // Animate cards when in view
            whileHover={{
              scale: 1.05,
              backgroundColor: "#23d997",
            }} // Add hover effect
          >
            <IconContainer
              variants={iconVariants}
              animate="animate" // Continuous up-and-down motion
            >
              {service.icon}
            </IconContainer>
            <h3>{service.title}</h3>
            <p>{service.shortDesc}</p>
          </Card>
        ))}
      </Cards>
    </Services>
  );
};

export default ServicesSection;

// Styled Components
const Services = styled(motion.div)`
  background-color: #1b1b1b;
  padding: 5rem 2rem;
  h2 {
    padding-bottom: 3rem;
    font-size: 3rem;
    text-align: center;
    color: #23d997;
  }

  @media (max-width: 1200px) {
    padding: 2rem;
    text-align: center;
  }
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  width: 80%;
  align-items: center;
  margin: 0 auto;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1rem;
    width: 100%;
  }
`;
const IconContainer = styled(motion.div)`
  width: 4rem;
  height: 4rem;
  margin: 0 auto 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #111111;
  border-radius: 50%;
  transition: background 0.3s ease, color 0.3s ease; // Smooth transition for icon color

  svg {
    color: white; // Default icon color
    transition: color 0.3s ease; // Smooth transition for icon color
  }
`;

const Card = styled(motion.div)`
  cursor: pointer;
  background: #282828;
  padding: 1.5rem;
  border-radius: 0.2rem;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  transition: background-color 0.3s ease;

  @media (max-width: 768px) {
    width: 100%;
    max-width: none;
    margin: 0;
  }

  h3 {
    margin: 0.8rem 0;
    font-size: 1.2rem;
    color: #ffffff;
    transition: color 0.3s ease; // Smooth color transition on hover
  }

  p {
    font-size: 0.9rem;
    color: #f1f1f1;
    line-height: 1.4;
    transition: color 0.3s ease; // Smooth color transition on hover
  }

  &:hover {
   background-color: #23d997; // Add hover effect for background

    h3 {
      color: black; // Change title color on hover
    }

    p {
      color: black; // Change description color on hover
    }

    ${IconContainer} {
      background: black; // Change icon background color on hover
      color: black; // Change icon color on hover
    }
  }

  &:active {
    transform: scale(
      0.98
    ); // Optional: adds a pressed effect when the card is clicked
  }
`;
