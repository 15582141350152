// src/components/CaseStudy.js
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  CaseStudyContainer,
  YellowBanner,
  WhiteSection,
  ProblemSection,
  SolutionSection,
  ImpactSection,
  TextSection,
  YellowBox,
  ProcessSection
  
  
} from "./CaseStudyStyles"; // Adjust import path if needed
import ideasb from "../img/ideas_b.PNG";
import ideas1 from "../img/ideas_1.PNG";
import ideas2 from "../img/ideas_2.PNG";
import ideas3 from "../img/ideas_3.PNG";
import ideas4 from "../img/ideas_4.PNG";
import aira_1 from "../img/aira_1.PNG";
import aira_2 from "../img/aira_2.PNG";
import aira_3 from "../img/aira_3.PNG";
import aira_4 from "../img/aira_4.PNG";
import airab from "../img/airab.PNG";
import sf_1 from "../img/sf_1.PNG";
import sf_2 from "../img/sf_2.PNG";
import sf_3 from "../img/sf_3.PNG";
import sf_4 from "../img/sf_4.PNG";

import ssl_1 from "../img/ssl_1.PNG";
import ssl_2 from "../img/ssl_2.PNG";
import ssl_3 from "../img/ssl_3.PNG";
import ssl_4 from "../img/ssl_4.PNG";
import sslb from "../img/sslb.PNG";
import sslb1 from "../img/sslb1.PNG";

import uwt from "../img/uwt.png";
import uw1 from "../img/uw1.png";
import uw2 from "../img/uw2.png";
import uw3 from "../img/uw3.png";
import uw4 from "../img/uw4.png";

import pvt from "../img/pvt.png";
import pv1 from "../img/pv1.png";
import pv2 from "../img/pv2.png";
import pv3 from "../img/pv3.png";
import pdt from "../img/pdt.PNG";


import mct from "../img/mct.png";
import mc1 from "../img/mc1.png";
import mc2 from "../img/mc2.png";
import mc3 from "../img/mc3.png";
import mc4 from "../img/mc4.png";

import aabt from "../img/aabt.png";
import ab1 from "../img/ab1.png";
import ab2 from "../img/ab2.png";
import ab3 from "../img/ab3.png";
import ab4 from "../img/ab4.png";

// Case study data
const caseStudies = {
  ideas: {
    title: "IDEAS Foundation",
    subtitle: "Web Application",
    bannerText: "IDEAS Foundation Web Application",
    description:
      "The foundation works towards enhancing the social status of orphans and widows through various educational and recreational initiatives and empowering street children with access to quality education and learning opportunities.",
      description1:
      "The IDEAS Foundation needed a modern, user-friendly web application to effectively communicate its mission of empowering street children with access to quality education. Their outdated website hindered navigation, reduced donor engagement, and failed to reflect their vision, impacting outreach and support efforts. We stepped in to build a solution that bridges these gaps through innovative design and cutting-edge technology.",
      problem:
      "The IDEAS Foundation's outdated website presented significant barriers to its mission of empowering street children and underserved communities. The lack of intuitive navigation made it difficult for donors and volunteers to engage effectively, while the platform's technical limitations hindered maintenance and scalability. Additionally, the website failed to clearly communicate the foundation’s vision, resulting in reduced trust, lower donor contributions, and limited outreach to potential supporters. These issues restricted the foundation’s ability to achieve its goals and extend its impact.",
    challenges:
      "The outdated website lacked intuitive navigation, making it difficult for donors and volunteers to engage effectively. It failed to convey the foundation’s mission clearly, which limited their ability to build trust, raise funds, and connect with potential supporters. Additionally, the technical constraints of the platform made it challenging to maintain or expand the site.",
    solution:
      "Our solution involved developing a responsive, user-friendly web application tailored to the foundation's needs. We incorporated a modern design with intuitive navigation, streamlined the donation process, added engaging animations, and built a custom admin panel to facilitate efficient content management and scalability.",
    impact:
      "The revamped website significantly increased donor engagement, volunteer registrations, and overall visibility for the IDEAS Foundation. The seamless navigation and updated design led to a rise in contributions and enabled the foundation to extend its reach, helping more underserved children gain access to quality education.",
    process:
      "We began by analyzing the foundation's challenges and goals, gathering insights from stakeholders and users. Using a user-centered design approach, we developed an intuitive, responsive interface that focused on accessibility and functionality. Through continuous feedback and iteration, we ensured the final solution met the foundation’s needs effectively.",
    bannerImage:
     ideasb,
    problemImage: ideas1, // Replace with real image
    solutionImage: ideas2, // Replace with real image
    impactImage: ideas3, // Replace with real image
    processImage: ideas4,
  },
  aira: {
    title: "AIRA Photo Editor App",
    subtitle: "Mobile Application",
    bannerText: "Create and Share Beautiful Moments",
    description:
      "A user-friendly app to enhance, edit, and share photos with ease and creativity.",
      description1:
      "AiRA is a groundbreaking photo filter app that blends exclusive filters with a vibrant social platform. It empowers users to explore, save, and share filters within their network, fostering creativity and collaboration. Featuring a personalized home page with trending filters, AiRA is designed to enhance both photo editing and social connections in a seamless and engaging way.",
      problem:
      "Creating a seamless blend of a feature-rich photo editing app with an engaging social platform posed several challenges. The app needed to handle high-quality image processing, integrate exclusive filters, and provide real-time interactions for users to explore, save, and share filters. Ensuring smooth cross-platform performance while maintaining a visually appealing and intuitive user experience added to the complexity. These challenges required a robust and innovative technical solution to meet user expectations and deliver a creative yet user-friendly app.",
    challenges: "The main challenge was building a feature-rich app that seamlessly integrated exclusive filters with a social platform. Ensuring real-time interactions, high-quality image processing, and smooth cross-platform performance required a thoughtful and technically robust approach.",
    solution:
      "We developed AiRA with standout features like exclusive filters, real-time social integration, and personalized filter discovery. Using Flutter and Dart for a consistent cross-platform experience, Firebase for real-time sync and authentication, and Python for server-side image processing, the app delivers smooth performance and unique capabilities.",
    impact:
      "AiRA successfully transformed photo editing into a community-driven experience. Users can create, share, and discover unique filters while enjoying intuitive navigation and seamless functionality. The app’s launch was met with high user satisfaction, fostering creativity and boosting engagement among its growing user base.",
    process:
      "The process started with understanding the client’s vision for a creative yet user-friendly app. We focused on developing intuitive navigation, high-quality image processing, and seamless real-time social features. Through iterative feedback and rigorous testing, we delivered a polished, dynamic app that exceeded expectations.",
    bannerImage:
      airab, // Replace with real image
    problemImage: aira_1, // Replace with real image
    solutionImage: aira_2, // Replace with real image
    impactImage: aira_3, // Replace with real image
    processImage: aira_4,
  },
  ssl: {
    title: "Students Social Life App",
    subtitle: "Platform for Students",
    bannerText: "Enhancing Campus Connections",
    description:
      "A platform designed to enhance students' social connections and organize campus events effectively.",
      description1:
      "The Student Social Life (SSL) App is a transformative platform designed to enhance the university experience. With features like student clubs, subject groups, internship opportunities, group chats, and personalized profiles, SSL empowers students to connect, learn, and grow academically, socially, and professionally.",
      problem:
      "Universities often face challenges in fostering meaningful student engagement due to a lack of a unified platform that integrates social, academic, and professional opportunities. Students struggle to discover and join clubs, collaborate on academic projects, or access internship opportunities efficiently. Without a centralized system, these gaps lead to missed opportunities for growth, limited collaboration, and fragmented campus interactions, creating a need for an innovative solution to bridge these divides and enhance the student experience.",
    challenges:
      "Universities often lack a unified platform to foster student engagement, making it difficult for students to find clubs, collaborate academically, and access professional opportunities. Bridging these gaps while providing a seamless user experience was the primary challenge.",
    solution:
      "SSL redefines campus life by offering a multifaceted app that includes club discovery, subject-based study groups, an internship marketplace, robust group chats, and personalized student profiles. Built as a web and mobile platform, it ensures flexibility and accessibility for students anytime, anywhere.",
    impact:
      "SSL has revolutionized how students interact within their university community. It has fostered greater student engagement, empowered local clubs, and opened pathways for professional growth through internships. The app has been praised for its intuitive design, vibrant community features, and the value it adds to students' academic and social lives.",
    process:
      "The development process focused on understanding student needs and university dynamics. Through user-centered design and iterative feedback, we crafted an app with a simple yet beautiful UI, personalized features, and seamless functionality to empower students in their academic journey and social interactions.",
    bannerImage:
      sslb1, // Replace with real image
    problemImage: ssl_1, // Replace with real image
    solutionImage: ssl_2, // Replace with real image
    impactImage: ssl_3, // Replace with real image
    processImage: ssl_4,
  },
  streetfeast: {
    title: "StreetFeast App",
    subtitle: "Food Sharing Platform",
    bannerText: "Connecting People Through Food",
    description:
      "An initiative to connect people with food-sharing opportunities, helping those in need.",
      description1:
      "Street Feast is a mobile app designed for street food enthusiasts, offering a platform to discover, rate, and share the best local street food vendors. It bridges the gap between small eateries and food lovers, creating a community-driven experience that celebrates flavors, cultures, and culinary adventures.",
      problem:
      "Small, local street food vendors often struggle to gain visibility on major food delivery platforms, limiting their customer base and revenue. Food enthusiasts, on the other hand, face challenges discovering hidden culinary gems that offer authentic and diverse flavors. The lack of an intuitive platform connecting these two groups created a gap in the food-sharing ecosystem. This gap needed to be addressed with a solution that balanced ease of use, robust search, and community-driven review features.",
    challenges: "The challenge was to create an intuitive app that helps users discover hidden culinary gems while supporting small, local vendors who lack visibility on major food delivery platforms. Balancing ease of use with robust search and review features was a key priority.",
    solution:
      "We built Street Feast with a sleek, user-friendly interface that allows users to search for vendors by taste or location, add new vendors, and share reviews. The app highlights unique street food spots, promotes cultural diversity, and supports small businesses in reaching a wider audience.",
    impact:
      "Street Feast has transformed the way users discover and engage with street food. It has empowered small vendors to gain visibility, increased foot traffic to local eateries, and created a vibrant community of food enthusiasts who celebrate local flavors and cultures.",
    process:
      "Our process started with user research to understand the needs of both food enthusiasts and local vendors. We developed a clean, intuitive UI with features like area-wise search, vendor reviews, and cultural food exploration. Through iterative testing and feedback, we delivered an app that connects food lovers with hidden culinary treasures.",
    bannerImage:
      sslb, // Replace with real image
    problemImage: sf_4, // Replace with real image
    solutionImage: sf_1, // Replace with real image
    impactImage: sf_2, // Replace with real image
    processImage: sf_3,
  },
  urduwebify:
  {
    title: "Urdu Webify",
    subtitle: "Conversational AI Web App",
    bannerText: "Transforming Urdu Speech into Functional Webpages",
    description:
      "Users can speak in Urdu to describe their desired webpage structure or content, and Urdu Webify transforms these commands into fully functional webpages.",
      description1:
      "Urdu Webify is an innovative tool that allows users to create fully functional webpages using natural Urdu speech. By enabling individuals to describe their desired webpage structure or content in Urdu, it transforms voice commands into code seamlessly. This revolutionary approach removes the barriers of language and technical skills, making web development accessible to anyone, regardless of their background.",
      problem:
      "In regions with limited access to coding education and technology, language and technical barriers prevent many individuals from participating in web development. Users without technical expertise or familiarity with coding languages face significant challenges in creating webpages. This gap highlights the need for an innovative tool that bridges natural language and web development, making it accessible to non-technical users, especially in underserved communities.",
    challenges:
      "The challenge was to break language and technical barriers, enabling users without coding skills to create webpages using Urdu speech. In a world where technology can feel inaccessible to those without technical education, there was a need for a tool that could bridge the gap between natural language and web development",
    solution:
      "Urdu Webify translates natural Urdu speech into functional webpage code, focusing on accessibility, creativity, and ease of use. By allowing users to describe their desired webpage structure or content in Urdu, it simplifies web development and removes the need for technical expertise.",
    impact:
      "Urdu Webify empowers small business owners, educators, and individuals to participate in the digital world, fostering economic growth and digital inclusion for underserved communities. It proves that technology can be a powerful enabler, providing opportunities for creativity, communication, and progress.",
      process:
      "The development of Urdu Webify began with a focus on accessibility and user experience. Extensive research was conducted to understand the needs of non-technical users, particularly in regions with limited access to coding education. We designed and implemented a robust system that processes natural Urdu speech and translates it into functional webpage code. Through iterative testing and feedback, we ensured the platform is intuitive, reliable, and capable of empowering users to bring their creative ideas to life.",
    bannerImage: uwt, // Replace with actual image path
    problemImage: uw1, // Add problem image
    solutionImage: uw2, // Add solution image
    impactImage: uw3, // Add impact image
    processImage: uw4,
  },
  PredictVid:
  {
    title: "Predict Vid",
    subtitle: "Future-Frame Predicting Web Application",
    bannerText: "Unlocking the Future with Advanced Deep Learning Models",
    description:
      "An advanced web application that predicts future video frames using deep learning models for seamless motion analysis and synthesis.",
      description1:
      "PredictVid is an innovative web application that predicts future video frames based on a short sequence of input frames. Users provide a short video clip, and the system processes the input frames to understand the context and motion within the video. By leveraging deep learning and advanced predictive algorithms, PredictVid can generate seamless future frames, enabling immersive storytelling, proactive decision-making, and real-time video synthesis.",
      problem:
      "Designing a system capable of accurately predicting what happens next in a video posed significant challenges. The complexity of understanding intricate motion patterns, maintaining visual consistency, and generating seamless transitions between predicted frames required advanced techniques. Additionally, limitations in processing speed, frame coherence, and the ability to handle diverse video scenarios made the task even more demanding. These challenges needed to be addressed to create a reliable and efficient future-frame predicting application.",
    challenges:
      "The challenge was to design a system capable of accurately predicting what happens next in a video. Understanding complex motion patterns, preserving visual consistency, and ensuring seamless transitions between predicted frames were critical hurdles. The project required overcoming limitations in processing speed, frame coherence, and handling diverse video scenarios.",
    solution:
      "We implemented a deep learning model that analyzes input video frames to extract motion context and visual data. By training the system on various scenarios, it generates accurate and consistent future video frames. The application features an interactive user interface for smooth video input and playback, along with seamless video synthesis for a predictive, immersive experience.",
    impact:
      "PredictVid transforms how we interact with visual data by providing predictive insights into motion. Its ability to forecast future frames unlocks new possibilities for industries such as entertainment, security, and real-time video processing. The application enables proactive decision-making, immersive storytelling, and dynamic scene predictions, revolutionizing video-based technologies.",
      process:
      "The development process began with analyzing existing video prediction models and identifying gaps in frame synthesis and motion understanding. We collected diverse video datasets to train the deep learning model to predict future frames accurately. Iterative testing and optimization ensured that the predicted frames maintained consistency and context. Finally, we developed an intuitive user interface that allows users to input video clips and experience real-time video frame prediction seamlessly.",
    bannerImage: pvt, // Replace with actual image path
    problemImage: pdt, // Add problem image
    solutionImage: pv1, // Add solution image
    impactImage: pv2, // Add impact image
    processImage: pv3,
  },
  MedicalChatbot:
  {
    title: "Medical Chatbot ",
    subtitle: "Chatbot Web Application",
    bannerText: "Revolutionizing Medical Information Access with AI-Powered Precision.",
    description:
      "An intelligent chatbot leveraging NLP and BioBERT to provide precise medical information based on Davidson's Principles and Practice of Medicine.",
      description1:
      "MedicalChatbot is an AI-driven system designed to provide accurate and context-aware medical information using cutting-edge natural language processing (NLP) techniques. Built on Davidson's Principles and Practice of Medicine, the chatbot efficiently answers user queries, offering valuable insights into cognitive biases, clinical decision-making, and medical concepts. By leveraging BioBERT, it understands complex medical questions and delivers highly relevant, context-aware responses, ensuring users receive precise and reliable medical guidance.",
      problem:
      "The creation of a medical chatbot presented significant challenges, as it needed to comprehend complex, domain-specific medical terminology and queries accurately. Medicine is a critical field where precision and reliability are paramount, leaving no room for error. Ensuring context-aware responses required the integration of vast and trusted medical knowledge, such as Davidson's Principles and Practice of Medicine. Additionally, preprocessing this extensive data while maintaining a user-friendly and intuitive interaction posed a formidable technical challenge.",
    challenges:
      "The challenge was to build a chatbot capable of understanding complex, domain-specific medical terminology and queries. Ensuring accuracy, reliability, and context-aware responses in a field as critical as medicine posed significant hurdles. Additionally, integrating vast medical knowledge from trusted sources required extensive preprocessing and optimization.",
    solution:
      "We utilized BioBERT, a specialized NLP model tailored for biomedical text, to enable the chatbot to comprehend complex medical questions effectively. By training the model on data from Davidson's Principles and Practice of Medicine, the system can retrieve accurate and contextually relevant answers. The chatbot also features an intuitive interface to ensure seamless interaction with users.",
    impact:
      "MedicalChatbot enhances access to trusted medical information, empowering users to make informed decisions about health and clinical topics. It reduces dependency on generic search engines, offering efficient and reliable query resolution for medical practitioners, students, and individuals seeking guidance. The chatbot bridges the gap between complex medical literature and accessible insights.",
      process:
      "The development process began with curating a comprehensive medical dataset based on Davidson's Principles and Practice of Medicine. We integrated BioBERT to process and understand biomedical queries accurately. Extensive testing was performed to ensure the chatbot's accuracy and reliability, followed by iterative fine-tuning to improve performance. Finally, we developed a user-friendly interface that simplifies interaction and query resolution, ensuring an efficient user experience.",
    bannerImage: mct, // Replace with actual image path
    problemImage: mc1, // Add problem image
    solutionImage: mc2, // Add solution image
    impactImage: mc3, // Add impact image
    processImage: mc4,
  },
  AiAutomatedBilling:
  {
    title: "AI Automated Billing System ",
    subtitle: "Automated Billing Web Application",
    bannerText: "Automate Billing with Real-Time Product Recognition .",
    description:
      "An AI-powered billing system that detects multiple products from images, automating pricing and checkout for seamless and efficient billing.",
      description1:
      "The AI Automated Billing System eliminates the need for manual product scanning or barcode identification by using advanced AI to detect and recognize multiple products directly from images. Once the products are identified, their corresponding prices are fetched from the database in real time and dynamically displayed, streamlining the billing process. This innovative system increases accuracy, reduces errors, saves time, and provides an enhanced checkout experience for customers and businesses alike.",
      problem:
      "Traditional billing systems heavily rely on manual input or barcode scanning, which is often slow, error-prone, and inefficient, especially during peak hours. This process creates bottlenecks, frustrates customers, and increases operational costs for businesses. Additionally, recognizing multiple products from a single image while ensuring real-time pricing updates posed significant technical challenges, requiring an innovative approach to streamline the billing process effectively.",
    challenges:
      "Traditional billing systems rely on manual input or barcode scanning, which can be slow, error-prone, and inefficient, especially during peak hours. Developing a system capable of accurately recognizing multiple products from a single image while ensuring real-time pricing updates posed significant technical challenges.",
    solution:
      "Our AI-powered solution leverages advanced image recognition algorithms to detect and identify multiple products in a single image. The system dynamically fetches the prices from a connected database and displays them on the interface in real time. By automating the entire billing process, the solution eliminates manual scanning, reduces errors, and significantly improves operational efficiency.",
    impact:
      "The AI Automated Billing System transforms traditional checkout processes, offering a seamless and accurate billing experience. Businesses benefit from reduced costs, increased accuracy, and faster processing times, while customers enjoy a smoother and hassle-free checkout. This innovation enhances customer satisfaction and reduces operational bottlenecks.",
      process:
      "The development process started with training AI models on diverse datasets to recognize products in images with high accuracy. The system was integrated with a dynamic pricing database to fetch product prices in real time. Rigorous testing was conducted to ensure consistent performance across various product types and lighting conditions. Finally, the user interface was designed for simplicity, enabling seamless integration into retail environments for both customers and staff.",
    bannerImage: aabt, // Replace with actual image path
    problemImage: ab1, // Add problem image
    solutionImage: ab2, // Add solution image
    impactImage: ab3, // Add impact image
    processImage: ab4,
  },

};
const CaseStudy = () => {
  const { id } = useParams();
  const [caseStudy, setCaseStudy] = useState(null);
   // Scroll to the top when the component mounts
   useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  useEffect(() => {
    setCaseStudy(caseStudies[id]);
  }, [id]);

  if (!caseStudy) {
    return (
      <CaseStudyContainer>
        <p>Case Study Not Found!</p>
      </CaseStudyContainer>
    );
  }

  return (
    <CaseStudyContainer>
      {/* Yellow Banner Section */}
      
      <YellowBanner bannerImage={caseStudy.bannerImage}>
  <div className="banner-content">
    <h1>{caseStudy.bannerText}</h1>
    <p>{caseStudy.description}</p>
  </div>
  
  <div className="banner-image">
    <img src={caseStudy.bannerImage} alt="Banner" />
  </div>
</YellowBanner>






      {/* Text Section Below Yellow Banner */}
      <TextSection>
        <div className="content">
          <h1>{caseStudy.title}</h1>
          <h2>{caseStudy.subtitle}</h2>
          <p>{caseStudy.description1}</p>
        </div>
      </TextSection>

      {/* Problem Section */}
      <ProblemSection>
        <div className="content-container">
          <div className="text-content">
            <div className="highlight">
              <hr />
              <span>Problem</span>
            </div>
            <h1>An Essential Collaboration</h1>
            <p>{caseStudy.problem}</p>
          </div>
          <div className="image-container">
            <img src={caseStudy.problemImage} alt="Problem" />
          </div>
        </div>
      </ProblemSection>

      {/* Yellow Box for Challenges */}
      <YellowBox>
        <h2>Challenges</h2>
        <p>{caseStudy.challenges}</p>
      </YellowBox>

      {/* Solution Section */}
      <SolutionSection>
        <div className="content-container">
          <div className="image-container">
            <img src={caseStudy.solutionImage} alt="Solution" />
          </div>
          <div className="text-content">
            <div className="highlight">
              <hr />
              <span>Solution</span>
            </div>
            <h1>Our Approach</h1>
            <p>{caseStudy.solution}</p>
          </div>
        </div>
      </SolutionSection>

      {/* Process Followed Section */}
      <ProcessSection>
        <div className="content-container">
          <div className="text-content">
            <div className="highlight">
              <hr />
              <span>Process Followed</span>
            </div>
            <h1>Our Workflow</h1>
            <p>{caseStudy.process}</p>
          </div>
          <div className="image-container">
            <img src={caseStudy.processImage} alt="Process" />
          </div>
        </div>
      </ProcessSection>

    {/* Impact Section */}
    <ImpactSection>
        <div className="content-container">
          <div className="image-container">
            <img src={caseStudy.impactImage} alt="Impact" />
          </div>
          <div className="text-content">
            <div className="highlight">
              <hr />
              <span>Impact</span>
            </div>
            <h1>Results and Impact</h1>
            <p>{caseStudy.impact}</p>
          </div>
        </div>
      </ImpactSection>
    </CaseStudyContainer>
  );
};

export default CaseStudy;
